<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
     <div class="text-center">
      <h4 class="mt-5">
اضافة سلايدر

       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save" ref="form">
        <b-row>
          
          <b-col md="6">
            <b-form-group
              label="العنوان"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Slide Title"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="addCourseForm.title" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group
              label="العنوان الفرعي"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="Slide Sub Title"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addCourseForm.subtitle"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
             
          <b-col md="6">
            <b-form-group
              label="نص الزر"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Button Text"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="addCourseForm.button" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col md="6">
            <b-form-group
              label="رابط الزر"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="Button URL"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addCourseForm.link"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Slide صورة</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewE2.src = Tabimage">
                ازالة الصورة
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block  mt-1">
                  <b-form-group
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                   <validation-provider
                  #default="{ errors }"
                  name="Slide Image"
                  rules="required"
                >
                    <b-form-file
                      ref="refInputE2"
                      v-model="addCourseForm.image"
                      accept=".jpg, .png, .gif, .jpeg"
                      placeholder="اختر ملف"
                      @input="inputImageRendererTab"
                    />
                     <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
                    <b-form-group
                    label="نص بديل للصورة"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
     
          </b-col>
        
        
          
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
          حفظ
            </b-button>
            
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
const Tabimage = ref("media/svg/files/blank-image.svg");

    const addCourseForm = reactive({
    'title':'', 
            'subtitle':'',
            'image':'',
            'button':'',
            'link':'' 
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
     const addCourseFormvalidate=ref()
     const form=ref()
    const save = () => {
      
      console.log(addCourseForm)
      const formData = new FormData();

    
        
         addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("title", addCourseForm.title);
formData.append("subtitle", addCourseForm.subtitle);
formData.append("image", addCourseForm.image);
formData.append("button", addCourseForm.button);
formData.append("link", addCourseForm.link);


formData.append("alt_image", addCourseForm.alt_image);

           store.dispatch('slides/addSlider',formData)
        .then(response => {
          form.value.reset()
           
                refPreviewE2.value.src ="media/svg/files/blank-image.svg"
           Vue.swal({
            title: "تم الاضافة بنجاح ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "حسنا",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
      console.log("alt_header_image", addCourseForm);
        }})
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      form,
  
     

      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
